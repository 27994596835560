import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid'
import { Employee } from '../../../../types/Employee'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useState } from 'react'
import Modal from '../../../UI/Modal/Modal'
import { Source } from '../../../../types/Survey'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import Button from '../../../UI/Button/Button'
import Loading from '../../../UI/Loading/Loading'
import { getPrettyDateTime } from '../../../../utility'

type Props = {
  employee: Employee
  callback: (employee: Employee) => void
}

enum Status {
  DEFAULT = 'DEFAULT',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS_EMPLOYEE = 'SUCCESS_EMPLOYEE',
  SUCCESS_CLIENT = 'SUCCESS_CLIENT',
}

const SurveyManager: React.FC<Props> = ({ employee, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const sendSurveyMail = functional.use(Routes.SEND_SURVEY_MAIL)
  const toast = useToast()
  const [status, setStatus] = useState<Status>(Status.DEFAULT)

  const toggle = () => setOpen(!open)

  const trigger = (source: Source) => async () => {
    setStatus(Status.IN_PROGRESS)
    const updatedEmployee = await sendSurveyMail({ employeeUUID: employee.uuid, source })
    setStatus(Status.DEFAULT)
    if (updatedEmployee) {
      setStatus(source === Source.EMPLOYEE_SURVEY ? Status.SUCCESS_EMPLOYEE : Status.SUCCESS_CLIENT)
      toast(t.employee.list.surveyManager.success, Type.SUCCESS)
      callback(updatedEmployee)
    } else {
      toast(t.employee.list.surveyManager.error, Type.ERROR)
    }
  }

  return (
    <div>
      <Tooltip text={t.employee.list.surveyManager.tooltip}>
        <ClipboardDocumentListIcon onClick={toggle} className="h-5 fill-blue cursor-pointer" />
      </Tooltip>
      <Modal show={open} onClose={toggle}>
        <div className="text-blue font-bold">{`${t.employee.list.surveyManager.titlePrefix}${employee.vorname} ${employee.nachname}`}</div>
        {status === Status.IN_PROGRESS && <Loading loading={false} />}
        {status !== Status.IN_PROGRESS && (
          <div className="flex flex-col gap-3 mt-3">
            <div className="border border-blue p-3">
              <div className="font-bold">{t.employee.list.surveyManager.title.employee}</div>
              <div className="mt-2">{t.employee.list.surveyManager.hint.employee}</div>
              <div className="mt-2 font-bold text-sm">
                {t.employee.list.surveyManager.alreadySent}
                {!!employee.sentToEmployeeTimestamp ? getPrettyDateTime(new Date(employee.sentToEmployeeTimestamp)) : '-'}
              </div>
              <div className="font-bold text-sm">
                {t.employee.list.surveyManager.editedBy}
                {!!employee.editedByEmployeeTimestamp ? getPrettyDateTime(new Date(employee.editedByEmployeeTimestamp)) : '-'}
              </div>
              {!!employee.email ? (
                <Button className="ml-auto mt-2" onClick={trigger(Source.EMPLOYEE_SURVEY)} text={t.employee.list.surveyManager.cta} />
              ) : (
                <div className="mt-2 font-bold text-center">{t.employee.list.surveyManager.mailNotAvailable}</div>
              )}
            </div>
            <div className="border border-blue p-3">
              <div className="font-bold">{t.employee.list.surveyManager.title.client}</div>
              <div className="mt-2">{t.employee.list.surveyManager.hint.client}</div>
              <div className="mt-2 font-bold text-sm">
                {t.employee.list.surveyManager.alreadySent}
                {!!employee.sentToClientTimestamp ? getPrettyDateTime(new Date(employee.sentToClientTimestamp)) : '-'}
              </div>
              <div className="font-bold text-sm">
                {t.employee.list.surveyManager.editedBy}
                {!!employee.editedByClientTimestamp ? getPrettyDateTime(new Date(employee.editedByClientTimestamp)) : '-'}
              </div>
              <Button className="ml-auto mt-2" onClick={trigger(Source.CLIENT_SURVEY)} text={t.employee.list.surveyManager.cta} />
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default SurveyManager
