import { useState } from 'react'
import FileSelect from '../FileSelect/FileSelect'
import useCSVImport from './useCSVImport'
import Button from '../Button/Button'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import useToast, { Type as ToastType } from '../../hooks/useToast'
import Loading from '../Loading/Loading'

export enum Type {
  DATA_FIELD = 'DATA_FIELD',
  CLIENT = 'CLIENT',
  EMPLOYEE = 'EMPLOYEE',
}

type Props = { type: Type; className?: string; metaData?: any; successCallback?: (data: any[]) => void }

const CSVImport: React.FC<Props> = ({ type, className, metaData, successCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [file, setFile] = useState<File[]>(null)
  const csvImport = useCSVImport(type)
  const [loading, setLoading] = useState<boolean>(false)
  const toast = useToast()

  const start = async () => {
    setLoading(true)
    const result = await csvImport(file[0], metaData)
    setLoading(false)
    setFile(null)
    if (result) {
      if (successCallback) {
        successCallback(result)
      }
    } else {
      toast(t.UI.csvImport.error, ToastType.ERROR)
    }
  }

  const fileSelected = Array.isArray(file) && file.length > 0

  return (
    <div className={`border border-dotted border-blue p-2 ${className}`}>
      <div className="text-blue text-sm font-bold">{t.UI.csvImport.title}</div>
      {!loading && (
        <FileSelect onFiles={(f) => setFile(f)} acceptedMimeTypes={['text/csv']} maxFileSize={10000000}>
          <ArrowUpTrayIcon className="stroke-blue w-8" />
        </FileSelect>
      )}
      {loading && (
        <div className="flex flex-col justify-center">
          <Loading loading={false} />
          <div className="text-sm text-center">{t.UI.csvImport.loading}</div>
        </div>
      )}
      {!!fileSelected && !loading && (
        <div className="flex flex-row gap-3 items-center mt-3">
          <Button className="shrink-0" text={t.UI.csvImport.startCTA} onClick={start} />
          <div className="text-sm truncate">{file[0].name}</div>
        </div>
      )}
    </div>
  )
}

export default CSVImport
