import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { DataField } from '../../../../../types/DataField'
import Modal from '../../../../UI/Modal/Modal'
import { useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { DataFieldEntry } from '../../../../../types/DataFieldEntry'
import Input from '../../../../UI/Input/Input'
import useToast, { Type } from '../../../../hooks/useToast'
import CSVImport, { Type as CSVType } from '../../../../UI/CSVImport/CSVImport'
import { PlusIcon } from '@heroicons/react/24/solid'

type Props = { clientUUID?: string; dataField: DataField; addImported: (dataFieldEntrys: DataFieldEntry[]) => void }

const Add: React.FC<Props> = ({ clientUUID, dataField, addImported }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const [open, setOpen] = useState<boolean>(false)
  const dataFieldEntryFeature = crud.use(props.DATA_FIELD_ENTRY)
  const [newEntry, setNewEntry] = useState<DataFieldEntry>({
    clientUUID,
    attribute: dataField.attribute,
    name: '',
    exportKey: '',
  })

  const toggle = () => setOpen(!open)

  const set = (key: string) => (value: string) => setNewEntry({ ...newEntry, [key]: value })

  const create = async () => {
    const entry = await dataFieldEntryFeature.create(newEntry)
    if (entry) {
      addImported([entry])
      toggle()
      setNewEntry({
        clientUUID,
        attribute: dataField.attribute,
        name: '',
        exportKey: '',
      })
    } else {
      toast(t.settings.dataField.entry.add.error, Type.ERROR)
    }
  }

  return (
    <>
      <div className="flex justify-end">
        <div onClick={toggle} className="border border-blue bg-blue rounded-sm p-1 group hover:bg-white transition-all cursor-pointer">
          <PlusIcon className="h-6 w-6 fill-white group-hover:fill-blue" />
        </div>
      </div>
      <Modal confirmButtonText={t.settings.dataField.entry.add.cta} onConfirm={create} show={open} onClose={toggle}>
        <div className="text-blue font-bold">{t.settings.dataField.entry.add.importTitle}</div>
        <CSVImport
          type={CSVType.DATA_FIELD}
          className="mt-3"
          metaData={{ clientUUID, attribute: dataField.attribute }}
          successCallback={(entries) => {
            addImported(entries)
            toggle()
          }}
        />
        <div className="text-blue font-bold mt-6">{t.settings.dataField.entry.add.manualTitle}</div>
        <div className="flex flex-row gap-3 border-blue">
          <Input required value={newEntry.name} onChange={set('name')} label={t.settings.dataField.entry.attributes.name} />
          <Input value={newEntry.exportKey} onChange={set('exportKey')} label={t.settings.dataField.entry.attributes.exportKey} />
        </div>
      </Modal>
    </>
  )
}

export default Add
