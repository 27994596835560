import { ArrowsPointingInIcon, ArrowsPointingOutIcon, EyeIcon } from '@heroicons/react/24/solid'
import Input from '../Input/Input'
import { useSelector } from 'react-redux'
import globalProps from '../../../redux/props'
import { useState } from 'react'
import _ from 'lodash'
import { getUUID } from '../../../utility'
import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import Dropdown from '../Dropdown/Dropdown'
import Modal from '../Modal/Modal'
import usePLListIdentifier from '../../hooks/usePLListIdentifier'
import { IdentifierContext } from '../../../types/PL'
import Loading from '../Loading/Loading'
import useToast, { Type } from '../../hooks/useToast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Refresh from '../Refresh/Refresh'

type ValueParser = (item: any) => string | React.ReactNode

enum MultiOption {
  EXPORT = 'EXPORT',
  STOP = 'STOP',
  PROCESS = 'PROCESS',
}

type Props = {
  title: string
  list: any[]
  headerProps?: string[]
  valueParser: ValueParser[]
  propTranslationObject: any
  pull?: () => void
  filter: (search: string) => (item: any) => boolean
  linkGenerator?: (item: any) => string
  Add?: React.FC<{ successCallback: () => void }>
  triggerExport?: (selectedItemUUIDList: string[], identifier?: string) => void
  identifierContext: IdentifierContext
}

const MultiList: React.FC<Props> = ({
  title,
  list,
  headerProps,
  valueParser,
  propTranslationObject,
  filter,
  pull,
  linkGenerator,
  Add,
  triggerExport,
  identifierContext,
}) => {
  const t = useSelector((s) => s[globalProps.TRANSLATION])
  const [search, setSearch] = useState<string>('')
  const [selectedItemUUIDList, setSelectedItemUUIDList] = useState<string[]>([])
  const [selectActive, setSelectActive] = useState<boolean>(false)
  const [multiOption, setMultiOption] = useState<MultiOption>()
  const [showExport, setShowExport] = useState<boolean>(false)
  const identifierList = usePLListIdentifier(identifierContext)
  const [selectedExportIdentifier, setSelectedExportIdentifier] = useState<string>()
  const toast = useToast()
  const updateRunStatus = functional.use(Routes.PL_RUN_UPDATE_STATUS)

  const toggleSelected = () => setSelectActive(!selectActive)

  const toggleItem = (uuid: string) => () => {
    if (selectedItemUUIDList.includes(uuid)) {
      setSelectedItemUUIDList(selectedItemUUIDList.filter((i) => i !== uuid))
    } else {
      setSelectedItemUUIDList([...selectedItemUUIDList, uuid])
    }
  }

  const getOptions = () => {
    const options: MultiOption[] = []
    if (!!triggerExport) {
      options.push(MultiOption.EXPORT)
    }
    if (!!identifierContext.processUUID || identifierContext.global === true) {
      options.push(MultiOption.PROCESS)
      options.push(MultiOption.STOP)
    }
    return options
  }

  const triggerOption = async () => {
    if (multiOption === MultiOption.EXPORT) {
      if (identifierContext.global === true) {
        toggleShowExport()
      } else {
        triggerExport(selectedItemUUIDList, selectedExportIdentifier)
      }
    } else if (multiOption === MultiOption.PROCESS || multiOption === MultiOption.STOP) {
      const result = await updateRunStatus({ option: multiOption, uuidList: selectedItemUUIDList, isGlobal: identifierContext.global === true })
      if (result) {
        pull()
      } else {
        toast(t.UI.multiList.error, Type.ERROR)
      }
    }
  }

  const triggerInternalExport = () => {
    if (selectActive) {
      triggerExport(selectedItemUUIDList, selectedExportIdentifier)
    } else {
      triggerExport([], selectedExportIdentifier)
    }
    setShowExport(false)
    setSelectActive(false)
  }

  const toggleShowExport = () => setShowExport(!showExport)

  const isToggleAllActive = () => selectedItemUUIDList.length === list.length

  const toggleAll = () => {
    if (isToggleAllActive()) {
      setSelectedItemUUIDList([])
    } else {
      setSelectedItemUUIDList(list.map((i) => i.uuid))
    }
  }

  return (
    <div className="flex flex-col gap-1 py-10">
      <div className="flex gap-3 justify-between items-center">
        <div className="flex flex-row gap-3 items-center">
          <div className="font-bold text-lg text-blue">{title}</div>
          {!!pull && <Refresh pull={pull} />}
          {!!Add && !selectActive && <Add successCallback={pull} />}
          {!!triggerExport && !selectActive && list.filter(filter(search)).length > 0 && (
            <>
              {!!identifierContext.runUUID && <Button onClick={triggerInternalExport} text={t.UI.multiList.export.title} />}
              {!identifierContext.runUUID && <Button onClick={toggleShowExport} text={t.UI.multiList.export.title} />}
            </>
          )}
        </div>
        {getOptions().length > 0 && list.filter(filter(search)).length > 0 && (
          <>
            {!selectActive && <ArrowsPointingOutIcon onClick={toggleSelected} className="text-blue w-5 cursor-pointer" />}
            {selectActive && <ArrowsPointingInIcon onClick={toggleSelected} className="text-blue w-5 cursor-pointer" />}
          </>
        )}
      </div>
      <Input placeholder={t.UI.multiList.search} onChange={setSearch} value={search} className="mt-5" />
      <div className="mt-3">
        {list.filter(filter(search)).length}
        {t.UI.multiList.resultAmountSuffix}
      </div>
      <div className="mt-0">
        {list.length > 0 && (
          <>
            {!!headerProps && (
              <div className="flex flex-col lg:flex-row">
                {headerProps.map((p) => (
                  <div className="flex-1 shrink-0" key={p}>
                    <div className="font-bold">{_.get(propTranslationObject, p)}</div>
                  </div>
                ))}
                {!!linkGenerator && !selectActive && <div className="w-10 shrink-0"></div>}
                {selectActive && (
                  <div className="w-10 shrink-0">
                    <Checkbox checked={isToggleAllActive()} onChange={toggleAll} />
                  </div>
                )}
              </div>
            )}
            {list.filter(filter(search)).map((item) => {
              return (
                <div className="flex flex-col lg:flex-row items-center" key={item.uuid}>
                  {valueParser.map((parser) => (
                    <div className="flex-1 shrink-0" key={getUUID()}>
                      <div>{parser(item)}</div>
                    </div>
                  ))}
                  {!!linkGenerator && !selectActive && (
                    <div className="w-10 shrink-0">
                      <a href={linkGenerator(item)}>
                        <EyeIcon className="h-5 fill-blue" />
                      </a>
                    </div>
                  )}
                  {selectActive && (
                    <div className="w-10 shrink-0">
                      <Checkbox checked={selectedItemUUIDList.includes(item.uuid)} onChange={toggleItem(item.uuid)} />
                    </div>
                  )}
                </div>
              )
            })}
          </>
        )}
        {list.length === 0 && <div className="text-gray text-center text-sm">{t.UI.multiList.noData}</div>}
      </div>
      {selectActive && selectedItemUUIDList.length > 0 && (
        <div className="p-3 fixed bottom-0 left-0 w-full bg-blue">
          <div className="flex flex-col gap-3 md:flex-row justify-end">
            <Dropdown
              className="!w-fit"
              items={getOptions().map((o) => ({ label: `${t.UI.multiList.option[o]}`, value: o }))}
              value={multiOption}
              onChange={setMultiOption}
            />
            {!!multiOption && <Button onClick={triggerOption} light className="bg-lightGray" text={t.UI.multiList.cta} />}
            <Button onClick={() => setSelectActive(false)} light className="bg-lightGray" text={t.UI.multiList.cancelCTA} />
          </div>
        </div>
      )}
      <Modal onConfirm={triggerInternalExport} show={showExport} onClose={toggleShowExport}>
        <div className="text-blue font-bold">{t.UI.multiList.export.title}</div>
        <Loading loading={identifierList} />
        {!!identifierList && (
          <Dropdown
            items={identifierList.map((i) => ({ label: i, value: i }))}
            value={selectedExportIdentifier}
            onChange={setSelectedExportIdentifier}
            label={t.UI.multiList.export.identifier}
          />
        )}
      </Modal>
    </div>
  )
}

export default MultiList
