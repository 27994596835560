import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import Accordion from '../../UI/Accordion/Accordion'
import DataFields from './DataFields/DataFields'
import Access from './Access/Access'

const Settings = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const items = [
    { key: t.settings.dataField.title, title: t.settings.dataField.title, content: <DataFields /> },
    { key: t.settings.access.title, title: t.settings.access.title, content: <Access /> },
  ]

  return (
    <Container>
      <div className="flex flex-col gap-1 py-10">
        <div className="font-bold text-lg text-blue">{t.settings.title}</div>
        <Accordion className="mt-3" items={items} />
      </div>
    </Container>
  )
}

export default Settings
