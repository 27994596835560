import Input from '../UI/Input/Input'
import { DataField, InputType } from '../../types/DataField'
import DropdownWithSearch from '../UI/DropdownWithSearch/DropdownWithSearch'
import useDataFieldManager from '../hooks/useDataFieldManager'
import IbanInput from '../UI/Input/IbanInput'
import { DataFieldEntry } from '../../types/DataFieldEntry'
import Currency from '../UI/Input/Currency'
import Number from '../UI/Input/Number'

type Props = {
  clientUUID: string
  attribute: string
  required: boolean
  value: any
  onChange: (value: any) => void
  label?: string
  type?: string
}

const DynamicInput: React.FC<Props> = ({ clientUUID, required, attribute, value, onChange, label, type }) => {
  const dataFields: DataField[] = useDataFieldManager()

  const dataField = Array.isArray(dataFields)
    ? dataFields.find((df) => {
        return df.attribute === attribute
      })
    : false

  const entryFilter = (e: DataFieldEntry) => !e.clientUUID || e.clientUUID === clientUUID

  if (dataField && !!InputType[dataField.inputType]) {
    const internalLabel = label || dataField.name
    if (dataField.inputType === InputType.DROPDOWN) {
      return (
        <DropdownWithSearch
          items={dataField.entries.filter(entryFilter).map((e) => ({
            label: e.name,
            value: e.exportKey || e.name,
          }))}
          label={internalLabel}
          value={value}
          onChange={onChange}
          required={required}
        />
      )
    } else if (dataField.inputType === InputType.DATE) {
      return <Input label={internalLabel} type="date" required={required} value={value} onChange={onChange} />
    } else if (dataField.inputType === InputType.IBAN) {
      return <IbanInput label={internalLabel} required={required} value={value} onChange={onChange} />
    } else if (dataField.inputType === InputType.MAIL) {
      return <Input label={internalLabel} type="email" required={required} value={value} onChange={onChange} />
    } else if (dataField.inputType === InputType.NUMBER) {
      return <Number label={internalLabel} required={required} value={value} onChange={onChange} />
    } else if (dataField.inputType === InputType.NUMBER_DECIMAL) {
      return <Number label={internalLabel} required={required} value={value} onChange={onChange} decimal />
    } else if (dataField.inputType === InputType.CURRENCY) {
      return <Currency label={internalLabel} required={required} value={value} onChange={onChange} />
    } else {
      return <Input label={internalLabel} required={required} value={value} onChange={onChange} />
    }
  }
  return <Input required={required} label={label} type={type} value={value} onChange={onChange} />
}

export default DynamicInput
