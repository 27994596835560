import { DataFieldEntry } from './DataFieldEntry'

export enum InputType {
  INPUT = 'INPUT',
  DROPDOWN = 'DROPDOWN',
  DATE = 'DATE',
  IBAN = 'IBAN',
  MAIL = 'MAIL',
  NUMBER = 'NUMBER',
  NUMBER_DECIMAL = 'NUMBER_DECIMAL',
  CURRENCY = 'CURRENCY',
}

export type DataField = {
  uuid?: string
  name: string
  inputType: InputType
  exportName: string
  attribute: string
  changeableByClient: boolean
  changeableByEmployee: boolean
  position: number
  entries?: DataFieldEntry[]
}
