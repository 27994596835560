export const translation = {
  projectName: 'Bohmann-Laing',

  header: {
    navigation: {
      default: [
        {
          name: 'Login',
          link: '/',
        },
      ],
      ADMIN: [
        {
          name: 'Mandaten',
          link: '/admin/client',
        },
        {
          name: 'Pflegelotse',
          link: '/admin/pflegelotse',
        },
      ],
      MEMBER: [
        {
          name: 'Mandaten',
          link: '/member/client',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  login: {
    headline: 'Login',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  client: {
    list: {
      title: 'Mandanten',
      noData: 'Keine Daten vorhanden',
      search: 'Suche',
      employeeCount: 'Mitarbeiter',
      showArchived: 'Archivierte Mandanten anzeigen',
      tooltip: {
        goToEmployees: 'Mitarbeiter anzeigen',
        editClient: 'Mandant bearbeiten',
        deactivate: 'Mandant deaktivieren',
      },
    },
    attributes: {
      name: 'Mandantenname',
      nummer: 'Mandantennummer',
      betriebsstaette: 'Betriebsstätte',
      bundesland: 'Bundesland',
      kostenstellenname: 'Kostenstellenname',
      kostenstellennummer: 'Kostenstellennummer',
      branche: 'Branche',
      email: 'E-Mail-Adresse',
    },
    upsert: {
      createTitle: 'Mandant hinzufügen',
      updateTitle: 'Mandant bearbeiten',
      success: 'Mandant wurde erfolgreich gespeichert',
      error: 'Mandant konnte nicht gespeichert werden',
    },
  },

  employee: {
    list: {
      title: 'Mitarbeiter',
      noData: 'Keine Daten vorhanden',
      search: 'Suche',
      showArchived: 'Archivierte Mitarbeiter anzeigen',
      tooltip: {
        showDetail: 'Details anzeigen',
      },
      surveyManager: {
        tooltip: 'Fragebogen verschicken',
        titlePrefix: 'Fragebogen: ',
        cta: 'Fragebogen verschicken',
        hint: {
          employee: 'Der Mitarbeiter erhält eine E-Mail mit einem Link zum Fragebogen um seine Daten zu vervollständigen.',
          client: 'Der Mandant erhält eine E-Mail mit einem Link zum Fragebogen um die Daten des Mitarbeiters zu vervollständigen.',
        },
        title: {
          employee: 'Fragebogen an Mitarbeiter verschicken',
          client: 'Fragebogen an Mandant verschicken',
        },
        alreadySent: 'Zuletzt verschickt am: ',
        editedBy: 'Daten erhalten am: ',
        mailNotAvailable: 'E-Mail-Adresse nicht angegeben',
        success: 'Fragebogen wurde erfolgreich verschickt',
        error: 'Fragebogen konnte nicht verschickt werden',
      },
    },
    upsert: {
      createTitle: 'Mitarbeiter hinzufügen',
      updateTitle: 'Mitarbeiter bearbeiten',
      success: 'Mitarbeiter wurde erfolgreich gespeichert',
      error: 'Mitarbeiter konnte nicht gespeichert werden',
    },
    attributes: {
      anrede: 'Anrede',
      vorname: 'Vorname',
      nachname: 'Nachname',
      geburtsname: 'Geburtsname (sof. abweichend)',
      geburtsdatum: 'Geburtsdatum',
      geburtsort: 'Geburtsort',
      staatsangehoerigkeit: 'Staatsangehörigkeit',
      geburtsland: 'Geburtsland EU',
      impfstatus: 'Impfstatus',
      strasseHausnummer: 'Straße, Hausnummer',
      plz: 'PLZ',
      ort: 'Ort',
      konfession: 'Konfession',
      telefon: 'Telefon',
      handy: 'Handy',
      email: 'E-Mail-Adresse (privat)',
      krankenkasse: 'Krankenkasse',
      steuerklasse: 'Steuerklasse',
      familienstand: 'Familienstand',
      anzahlKinder: 'Anzahl Kinder',
      rentenversicherungsNr: 'Rentenversicherungs-Nr.',
      steuerID: 'Steuer-ID',
      schwerbehinderung: 'Schwerbehinderung',
      schulbildung: 'Schulbildung',
      ausbildung: 'Ausbildung',
      weitereBeschaeftigungen: 'Weitere parallele Beschäftigungen, SV-Pflichtig (Nebenarbeitgeber)',
      iban: 'IBAN',

      personalnummer: 'Personalnummer',
      eintrittsdatum: 'Eintrittsdatum',
      berufsgenossenschaft: 'Berufsgenossenschaft',
      gefahrentarifstelle: 'Gefahrentarifstelle',
      einsatzbereich: 'Einsatzbereich',
      arbeitnehmerueberlassung: 'Arbeitnehmerüberlassung',
      vertragsart: 'Vertragsart',
      geringfuegigBeschaeftigt: 'Geringfügig beschäftigt',
      gleitzone: 'Gleitzone',
      personengruppe: 'Personengruppe',
      arbeitszeitInStunden: 'Arbeitszeit (Stunden)',
      arbeitszeitPro: 'pro',
      stundenlohn: 'Stundenlohn (auch für Zuschläge)',
      zahlungsgrundlage: 'Zahlungsgrundlage',
      hausentgeltstrukturTarif: 'Hausentgeltstruktur/Tarif',
    },
  },

  employeeSurvey: {
    title: 'Mitarbeiterfragebogen',
    cta: 'Speichern',
    loading: 'Daten werden geladen...',
    consent: 'Hiermit bestätige ich die Richtigkeit meiner Angaben und stimme der Verarbeitung meiner Daten zu.',
    success: 'Wir haben Ihre Daten erhalten. Vielen Dank!',
    error: {
      loading: 'Ihre Daten konnten nicht geladen werden. Bitte versuchen Sie es später erneut.',
      submit: 'Ihre Daten konnten nicht gespeichert werden. Bitte versuchen Sie es später erneut.',
    },
  },

  clientSurvey: {
    title: 'Mitarbeiterfragebogen',
    cta: 'Speichern',
    loading: 'Daten werden geladen...',
    consent: 'Hiermit bestätige ich die Richtigkeit meiner Angaben und stimme der Verarbeitung der Daten zu.',
    success: 'Die Daten des Mitarbeiters wurden erfolgreich gespeichert. Vielen Dank!',
    error: {
      loading: 'Die Mitarbeiterdaten konnten nicht geladen werden. Bitte versuchen Sie es später erneut.',
      submit: 'Die Mitarbeiterdaten konnten nicht gespeichert werden. Bitte versuchen Sie es später erneut.',
    },
  },

  pflegelotse: {
    run: {
      list: {
        titlePrefix: 'Durchläufe - ',
        noData: 'Keine Daten vorhanden',
        search: 'Suche',
        columns: {
          identifier: 'Kennung',
          dataSetAmount: 'Datensätze',
          pageNumber: 'Seite',
          status: {
            title: 'Status',
            PENDING: 'Ausstehend',
            IN_PROGRESS: 'In Bearbeitung',
            STOPPED: 'Gestoppt',
            DONE: 'Fertig',
          },
        },
      },
      add: {
        title: 'Durchlauf hinzufügen',
        identifier: 'Kennung',
        loading: 'Prozess wird gestartet...',
        success: 'Prozess wurde erfolgreich gestartet',
        error: 'Prozess konnte nicht gestartet werden',
      },
      detail: {
        noData: 'Keine Daten vorhanden',
        entriesCountSuffix: ' Datensätze',
        placeVolumePrefix: 'Plätze: ',
        document: {
          title: 'Dokumente: ',
          bewertung: 'Bewertung',
          versorgungsinformationen: 'Versorgungsinformationen',
        },
      },
    },
    process: {
      list: {
        title: 'Datenpool',
        noData: 'Keine Daten vorhanden',
        search: 'Suche',
        columns: {
          location: 'Standort',
          radius: 'Radius',
          runAmount: 'Durchläufe',
          status: {
            title: 'Status',
            PENDING: 'Ausstehend',
            IN_PROGRESS: 'In Bearbeitung',
            STOPPED: 'Gestoppt',
            DONE: 'Fertig',
          },
        },
      },
      add: {
        title: 'Standort hinzufügen',
        postcodes: 'Postleitzahlen (getrennt durch Komma)',
        radius: 'Radius wählen',
        loading: 'Prozess wird gestartet...',
        success: 'Prozess wurde erfolgreich gestartet',
        error: 'Prozess konnte nicht gestartet werden',
      },
      detail: {
        noData: 'Keine Daten vorhanden',
        entriesCountSuffix: ' Datensätze',
        document: {
          title: 'Dokumente: ',
          bewertung: 'Bewertung',
          versorgungsinformationen: 'Versorgungsinformationen',
        },
      },
    },
  },

  settings: {
    title: 'Einstellungen',
    dataField: {
      title: 'Datenfelder',
      attributes: {
        name: 'Name',
        type: 'Typ',
        exportName: 'Export-Name',
        changable: {
          title: 'Sichtbar für:',
          client: 'Mandant',
          employee: 'Mitarbeiter',
        },
      },
      types: {
        INPUT: 'Texteingabe',
        DROPDOWN: 'Dropdown',
        DATE: 'Datum',
        IBAN: 'IBAN',
        MAIL: 'E-Mail Adresse',
        NUMBER: 'Zahl',
        NUMBER_DECIMAL: 'Zahl mit Dezimalstellen',
        CURRENCY: 'Währung',
      },
      edit: {
        title: 'Einstellungen bearbeiten',
        cta: 'Speichern',
        success: 'Datenfeld wurde erfolgreich gespeichert',
        error: 'Datenfeld konnte nicht gespeichert werden',
      },
      entry: {
        list: {
          genericTitle: 'Allgemeine Einträge',
          clientTitle: 'Mandanten spezifische Einträge',
          clientFilter: 'Suche nach Mandanten',
          perClientAmount: 'Einträge: ',
        },
        attributes: {
          name: 'Name',
          exportKey: 'Export-Schlüssel',
        },
        add: {
          importTitle: 'Einträge importieren',
          manualTitle: 'Manueller Eintrag',
          cta: 'Speichern',
          error: 'Einträge konnte nicht hinzugefügt werden',
        },
      },
    },
    access: {
      title: 'Zugriffsrechte',
      upsert: {
        title: 'Zugriff hinzufügen',
        cta: 'Zugriff hinzufügen',
        success: 'Zugriff wurde erfolgreich gespeichert',
        error: {
          upsert: 'Zugriff konnte nicht gespeichert werden',
          remove: 'Zugriff konnte nicht gelöscht werden',
          loading: 'Zugriffe konnten nicht geladen werden',
        },
        edit: 'Zugriff bearbeiten',
        remove: {
          title: 'Zugriff löschen',
          hint: 'Sind Sie sicher, dass Sie diesen Zugriff löschen möchten?',
          error: 'Zugriff konnte nicht gelöscht werden',
        },
      },
      entry: {
        attributes: {
          name: 'Name',
          exportKey: 'Export-Schlüssel',
        },
        add: {
          title: 'Einträge verwalten',
          cta: 'Erstellen',
          error: 'Eintrag konnte nicht hinzugefügt werden',
        },
      },
    },
  },

  footer: {
    links: [
      { name: 'Impressum', link: 'https://www.bohmann-laing.de/impressum/' },
      { name: 'Datenschutz', link: 'https://www.bohmann-laing.de/privacy-policy/' },
      { name: 'AGB', link: 'https://www.bohmann-laing.de/agb/' },
    ],
  },

  generic: {
    level: {
      title: 'Rolle',
      ADMIN: 'Administrator',
      MEMBER: 'Mitarbeiter',
    },
    company: {
      name: 'Bohmann-Laing GmbH',
      street: 'Hauptstr. 42',
      city: '49681 Garrel',
      phone: '04474/93954-0',
      mail: 'info@bohmann-laing.de',
    },
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    identifier: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
  },

  UI: {
    multiList: {
      search: 'Suche',
      resultAmountSuffix: ' Ergebnisse',
      noData: 'Keine Daten vorhanden',
      cta: 'Ausführen',
      cancelCTA: 'Abbrechen',
      option: {
        EXPORT: 'Export',
        PROCESS: 'Verarbeiten',
        STOP: 'Stoppen',
      },
      export: {
        title: 'Export',
        identifier: 'Kennung',
      },
      error: 'Aktion konnte nicht ausgeführt werden',
    },
    archiveMover: {
      title: {
        deactivate: (type: string) => `${type} archivieren`,
        activate: (type: string) => `${type} wiederherstellen`,
      },
      type: {
        CLIENT: 'Mandanten',
        EMPLOYEE: 'Mitarbeiter',
      },
      hint: {
        deactivate: (type: string) => `Sind Sie sicher, dass Sie diesen ${type} archivieren möchten?`,
        activate: (type: string) => `Sind Sie sicher, dass Sie diesen ${type} wiederherstellen möchten?`,
      },
      error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    },
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
      search: 'Suche',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'Die ausgewählte Datei ist zu groß: ',
      allowedFileTypes: 'Erlaubte Dateitypen: ',
      allowedFileSize: 'Max. erlaubte Dateigröße: ',
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Deine Ausgewählte PDF ist zu groß. Maximal erlaubte Dateigröße: 25 MB',
        type: 'Bitte lade nur PDF-Dateien hoch.',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
