import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { PL_Process } from '../../../../types/PL_Process'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Container from '../../../UI/Container/Container'
import MultiList from '../../../UI/MultiList/MultiList'
import Add from './Add'
import usePLExport, { ExportLevel } from '../../../hooks/usePLExport'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const plProcessList = functional.use(Routes.PL_PROCESS_LIST)
  const [list, setList] = useState<PL_Process[]>(null)
  const plExport = usePLExport(ExportLevel.PROCESS)

  const pull = useCallback(async () => {
    const result: PL_Process[] = await plProcessList({})
    if (Array.isArray(result)) {
      setList(result)
    }
  }, [plProcessList])

  useEffect(() => {
    if (!list) {
      pull()
    }
  }, [list, plProcessList, pull])

  const filter = (search: string) => (item: PL_Process) => {
    return item.location.city.toLowerCase().includes(search.toLowerCase()) || item.location.postcode.toLowerCase().includes(search.toLowerCase())
  }

  const headerProps = ['location', 'radius', 'runAmount']
  const valueParser = [
    (i: PL_Process) => `${i.location.postcode} (${i.location.city})`,
    (i: PL_Process) => `${i.radius}${t.generic.kilometerSuffix}`,
    (i: PL_Process) => i.runAmount.toString(),
  ]
  const linkGenerator = (i: PL_Process) => `/admin/pflegelotse/process/${i.uuid}`

  const triggerExport = (selectedItemUUIDList: string[], identifier: string) => {
    const fileName = `${identifier || 'allgemein'}.csv`
    plExport(fileName, null, selectedItemUUIDList, identifier)
  }

  if (!list) return <></>
  return (
    <Container>
      <MultiList
        title={t.pflegelotse.process.list.title}
        list={list}
        filter={filter}
        pull={pull}
        headerProps={headerProps}
        valueParser={valueParser}
        propTranslationObject={t.pflegelotse.process.list.columns}
        linkGenerator={linkGenerator}
        Add={Add}
        triggerExport={triggerExport}
        identifierContext={{ global: true }}
      />
    </Container>
  )
}

export default List
