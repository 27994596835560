import { Route, Routes } from 'react-router-dom'
import List from './Process/List'
import RunList from './Run/List'
import Run from './Run/Run'

const Pflegelotse = () => {
  return (
    <Routes>
      <Route path={'process/:processUUID'} element={<RunList />} />
      <Route path={'process/run/:runUUID'} element={<Run />} />
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}

export default Pflegelotse
