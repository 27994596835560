import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Client from '../SharedComponents/Client/Client'

const Member = () => {
  return (
    <Layout authLevelRequired={[Level.MEMBER]}>
      <Routes>
        <Route path={'client/*'} element={<Client />} />
        <Route path={'*'} element={<Navigate replace to="/member/client" />} />
      </Routes>
    </Layout>
  )
}

export default Member
