import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import ScrollToTop from './ScrollToTop/ScrollToTop'
import { AuthLevelRequired } from '../../../types/Auth'
import useDataFieldManager from '../../hooks/useDataFieldManager'

type Props = {
  children: React.ReactNode
  className?: string
  authLevelRequired?: AuthLevelRequired
  hideNavigation?: boolean
}

const Layout: React.FC<Props> = ({ children, className, hideNavigation, authLevelRequired = [] }) => {
  useDataFieldManager()

  return (
    <div className={className}>
      <Secure authLevelRequired={authLevelRequired}>
        <ScrollToTop />
        <Header hideNavigation={hideNavigation} />
        <div className="relative overflow-x-hidden bg-background min-h-screen pt-16">{children}</div>
        <Footer />
      </Secure>
    </div>
  )
}

export default Layout
