import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  MEMBER: 'MEMBER',
  CLIENT: 'CLIENT',
  EMPLOYEE: 'EMPLOYEE',
  DATA_FIELD: 'DATA_FIELD',
  DATA_FIELD_ENTRY: 'DATA_FIELD_ENTRY',

  // Pflegelotse features
  PL_PROCESS: 'PL_PROCESS',
  PL_RUN: 'PL_RUN',
  PL_DATASET: 'PL_DATASET',
}

export default props
