import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { useEffect, useState } from 'react'
import { IdentifierContext } from '../../types/PL'

const usePLListIdentifier = (identifierContext: IdentifierContext) => {
  const list = functional.use(Routes.PL_LIST_IDENTIFIER)
  const [identifierList, setIdentifierList] = useState<string[]>()

  useEffect(() => {
    const pull = async () => {
      const result: string[] = await list({ context: identifierContext })
      if (Array.isArray(result)) {
        setIdentifierList(result)
      }
    }
    if (!identifierList && !identifierContext.runUUID) {
      pull()
    }
  }, [identifierContext, list, identifierList])

  return identifierList
}

export default usePLListIdentifier
