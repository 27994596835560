import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import props from '../../../redux/props'
import useCSVExport from './useCSVExport'
import Routes from '../../../redux/routes'

type Props = { route: Routes; payload?: any; name: string }

const CSVExport: React.FC<Props> = ({ route, name, payload }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const csvExport = useCSVExport(route, payload)

  const triggerExport = () => {
    const filename = `${name}.csv`
    csvExport(filename)
  }

  return <Button onClick={triggerExport} text={t.generic.export} />
}

export default CSVExport
