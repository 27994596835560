import { useState } from 'react'
import { useSelector } from 'react-redux'
import useToast, { Type } from '../../hooks/useToast'
import props from '../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Client } from '../../../types/Client'
import Modal from '../../UI/Modal/Modal'
import Button from '../../UI/Button/Button'
import styled from 'styled-components'
import DynamicInput from '../../DynamicInput/DynamicInput'
import CSVImport, { Type as CSVType } from '../../UI/CSVImport/CSVImport'

const InputWrapper = styled.div`
  width: calc(100% / 2 - 5px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

type Props = { successCallback: () => void; children?: React.ReactNode; client?: Client }

const Upsert: React.FC<Props> = ({ successCallback, children, client = {} as Client }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const clientFeature = crud.use(props.CLIENT)
  const [data, setData] = useState<Client>(client)
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const upsert = async () => {
    const status = await clientFeature.upsert(data)
    if (status) {
      toast(t.client.upsert.success, Type.SUCCESS)
      successCallback()
      toggle()
    } else {
      toast(t.client.upsert.error, Type.ERROR)
    }
  }

  const set = (key: keyof Client) => (value: any) => setData({ ...data, [key]: value })

  return (
    <>
      {children ? (
        <div onClick={toggle} className="cursor-pointer">
          {children}
        </div>
      ) : (
        <Button onClick={toggle} text={t.generic.import} />
      )}
      <Modal onConfirm={upsert} show={open} onClose={toggle}>
        <div className="text-blue font-bold">{!!client?.uuid ? t.client.upsert.updateTitle : t.client.upsert.createTitle}</div>
        {!client.uuid && <CSVImport type={CSVType.CLIENT} className="mt-3" successCallback={successCallback} />}
        <div className="flex flex-row flex-wrap gap-[10px] mt-3">
          {Object.keys(t.client.attributes).map((a) => (
            <InputWrapper key={a}>
              <DynamicInput
                clientUUID={client.uuid}
                attribute={a}
                required
                label={t.client.attributes[a]}
                value={data[a]}
                onChange={set(a as keyof Client)}
                type={a === 'email' ? 'email' : undefined}
              />
            </InputWrapper>
          ))}
        </div>
      </Modal>
    </>
  )
}

export default Upsert
