import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import DynamicInput from '../../DynamicInput/DynamicInput'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { Employee } from '../../../types/Employee'
import { useEffect, useState } from 'react'
import useToast, { Type } from '../../hooks/useToast'
import Checkbox from '../../UI/Checkbox/Checkbox'
import Button from '../../UI/Button/Button'
import Form from '../../UI/Form/Form'
import Loading from '../../UI/Loading/Loading'
import { CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { LoadReponse, Source, State } from '../../../types/Survey'
import { DataField } from '../../../types/DataField'
import useDataFieldManager from '../../hooks/useDataFieldManager'

const InputWrapper = styled.div`
  width: calc(100% / 3 - (10px * 2 / 3));
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

enum Status {
  PENDING = 'PENDING',
  READY = 'READY',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const EmployeeSurvey = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { employeeUUID } = useParams()
  const loadEmployee = functional.use(Routes.LOAD_EMPLOYEE)
  const saveEmployee = functional.use(Routes.SAVE_EMPLOYEE)
  const [data, setData] = useState<Employee>()
  const [client, setClient] = useState<LoadReponse['client']>(null)
  const toast = useToast()
  const [consent, setConsent] = useState<boolean>(false)
  const [status, setStatus] = useState<Status>(Status.PENDING)
  const dataFields: DataField[] = useDataFieldManager()

  useEffect(() => {
    const pull = async () => {
      const result: LoadReponse | false = await loadEmployee({ uuid: employeeUUID, source: Source.EMPLOYEE_SURVEY })
      if (result) {
        if (result.state === State.ALREADY_SUBMITTED) {
          setStatus(Status.SUCCESS)
        } else if (result.state === State.SUCCESS) {
          setData(result.data)
          setClient(result.client)
          setStatus(Status.READY)
        } else {
          setStatus(Status.ERROR)
        }
      } else {
        toast(t.employeeSurvey.error, Type.ERROR)
        setStatus(Status.ERROR)
      }
    }
    if (!!employeeUUID && !data && status === Status.PENDING) pull()
  }, [employeeUUID, data, loadEmployee, toast, t, status])

  const submit = async () => {
    const result = await saveEmployee({ data, source: Source.EMPLOYEE_SURVEY })
    if (result) {
      toast(t.employeeSurvey.success, Type.SUCCESS)
      setStatus(Status.SUCCESS)
    } else {
      toast(t.employeeSurvey.error.submit, Type.ERROR)
    }
  }

  const set = (key: keyof Employee) => (value: any) => setData({ ...data, [key]: value })

  if (!dataFields) return <></>
  return (
    <Container>
      <div className="flex flex-col gap-1 py-10">
        <div className="font-bold text-lg text-blue">
          {t.employeeSurvey.title}
          {client ? ` - ${client.name}` : ''}
        </div>
        {status === Status.SUCCESS && (
          <div className="flex flex-col items-center mt-3">
            <CheckBadgeIcon className="w-20 fill-green" />
            <div className="font-bold">{t.employeeSurvey.success}</div>
          </div>
        )}
        {status === Status.ERROR && (
          <div className="flex flex-col items-center mt-3">
            <ExclamationTriangleIcon className="w-20 fill-orange" />
            <div className="font-bold">{t.employeeSurvey.error.loading}</div>
          </div>
        )}
        {status === Status.PENDING && (
          <div className="flex flex-col items-center mt-3">
            <div>{t.employeeSurvey.loading}</div>
            <Loading loading={false} />
          </div>
        )}
        {status === Status.READY && (
          <Form onSubmit={submit}>
            <div className="flex flex-row flex-wrap gap-[10px] mt-3">
              {dataFields
                .filter((df) => df.changeableByEmployee)
                .map((df) => (
                  <InputWrapper key={df.uuid}>
                    <DynamicInput
                      required
                      clientUUID={data.clientUUID}
                      attribute={df.attribute}
                      value={data[df.attribute]}
                      onChange={set(df.attribute as keyof Employee)}
                    />
                  </InputWrapper>
                ))}
            </div>
            <div className="flex flex-col items-end">
              <Checkbox required className="mt-3 !w-fit" checked={consent} onChange={setConsent} label={t.employeeSurvey.consent} />
              <Button type="submit" text={t.employeeSurvey.cta} className="mt-3" />
            </div>
          </Form>
        )}
      </div>
    </Container>
  )
}

export default EmployeeSurvey
