import { useState } from 'react'
import Modal from '../../../UI/Modal/Modal'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { PlusIcon } from '@heroicons/react/24/solid'
import Input from '../../../UI/Input/Input'
import Loading from '../../../UI/Loading/Loading'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import useToast, { Type } from '../../../hooks/useToast'
import { useParams } from 'react-router-dom'

const Add = ({ successCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [identifier, setIdentifier] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const createRun = functional.use(Routes.PL_RUN_CREATE)
  const { processUUID } = useParams()
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const create = async () => {
    setLoading(true)
    const status = await createRun({ processUUID, identifier })
    setLoading(false)
    if (status) {
      toast(t.pflegelotse.run.add.success, Type.SUCCESS)
      successCallback()
      toggle()
    } else {
      toast(t.pflegelotse.run.add.error, Type.ERROR)
    }
  }

  return (
    <>
      <div onClick={toggle} className="cursor-pointer">
        <PlusIcon className="text-blue w-5" />
      </div>
      <Modal onConfirm={create} show={open} onClose={toggle}>
        <div className="text-blue font-bold">{t.pflegelotse.run.add.title}</div>
        {!loading && (
          <div className="flex flex-col gap-2 mt-3">
            <Input required label={t.pflegelotse.run.add.identifier} value={identifier} onChange={setIdentifier} />
          </div>
        )}
        {loading && (
          <div className="flex flex-col gap-2 mt-3 items-center">
            <Loading loading={false} />
            <div className="text-sm text-gray">{t.pflegelotse.run.add.loading}</div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default Add
