import { useSelector } from 'react-redux'
import { DataFieldEntry } from '../../../../../types/DataFieldEntry'
import props from '../../../../../redux/props'

const List: React.FC<{ entries: DataFieldEntry[] }> = ({ entries }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div>
      <div className="flex flex-row gap-3 justify-between w-full">
        <div className="flex-1 text-sm font-bold ">{t.settings.dataField.entry.attributes.name}</div>
        <div className="flex-1 text-sm font-bold ">{t.settings.dataField.entry.attributes.exportKey}</div>
      </div>
      <div className="flex flex-col max-h-60 overflow-y-auto overflow-x-hidden">
        {entries.map((entry) => (
          <div key={entry.uuid} className="flex flex-row gap-3 justify-between w-full">
            <div className="flex-1">{entry.name}</div>
            <div className="flex-1">{entry.exportKey}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default List
