import { useCallback, useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../redux/props'
import Upsert from './Upsert'
import { Member as MemberType } from '../../../../types/Member'
import useToast, { Type } from '../../../hooks/useToast'
import { useSelector } from 'react-redux'

const Access = () => {
  const [members, setMembers] = useState<MemberType[]>(null)
  const memberFeature = crud.use(props.MEMBER)
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])

  const pullMembers = useCallback(async () => {
    const members = await memberFeature.list({})
    if (Array.isArray(members)) {
      setMembers(members)
    } else if (members === true) {
      setMembers([])
    } else {
      toast(t.settings.access.error.loading, Type.ERROR)
    }
  }, [memberFeature, t, toast])

  useEffect(() => {
    if (!members) pullMembers()
  }, [members, pullMembers])

  return (
    <div>
      <div className="flex justify-end">
        <Upsert successCallback={pullMembers} />
      </div>
      {!!members && (
        <div className="flex flex-col gap-1 mt-3">
          {members.map((member) => (
            <Upsert key={member.uuid} member={member} successCallback={pullMembers} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Access
