import { useCallback, useEffect, useState } from 'react'
import { DataField } from '../../../../types/DataField'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../redux/props'
import Single from './Single'
import { Client } from '../../../../types/Client'

const DataFields = () => {
  const [dataFields, setDataFields] = useState<DataField[]>(null)
  const dataFieldFeature = crud.use(props.DATA_FIELD)
  const [clients, setClients] = useState<Client[]>(null)
  const clientFeature = crud.use(props.CLIENT)

  const pullDataFields = useCallback(async () => {
    const dataFields = await dataFieldFeature.list({})
    if (Array.isArray(dataFields)) {
      setDataFields(dataFields)
    }
  }, [dataFieldFeature])

  const pullClients = useCallback(async () => {
    const clients = await clientFeature.list({ deactivated: false })
    if (Array.isArray(clients)) {
      setClients(clients)
    }
  }, [clientFeature])

  useEffect(() => {
    if (!dataFields) pullDataFields()
    if (!clients) pullClients()
  }, [dataFields, pullDataFields, clients, pullClients])

  if (!dataFields || !clients) return null
  return (
    <div>
      <div className="flex justify-end"></div>
      <div className="flex flex-row flex-wrap gap-[10px] mt-3">
        {dataFields?.map((dataField) => (
          <Single key={dataField.uuid} clients={clients} dataField={dataField} updateCallback={pullDataFields} />
        ))}
      </div>
    </div>
  )
}

export default DataFields
