import { PL_Location } from './PL_Location'

export enum PL_Radius {
  R5 = '5',
  R10 = '10',
  R15 = '15',
  R25 = '25',
  R50 = '50',
}

export type PL_Process = {
  uuid: string
  locationUUID: string
  radius: PL_Radius
  location?: PL_Location
  runAmount?: number
}
