enum Routes {
  GET_FILE_UPLOAD_PRESIGNED_URL = 'GET_FILE_UPLOAD_PRESIGNED_URL',

  EMPLOYEE_EXPORT = 'EMPLOYEE_EXPORT',
  CLIENT_EXPORT = 'CLIENT_EXPORT',
  LOAD_DATA_FIELDS = 'LOAD_DATA_FIELDS',
  CSV_IMPORT = 'CSV_IMPORT',
  LOAD_EMPLOYEE = 'LOAD_EMPLOYEE',
  SAVE_EMPLOYEE = 'SAVE_EMPLOYEE',
  TOGGLE_ARCHIVE_STATE = 'TOGGLE_ARCHIVE_STATE',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  SEND_SURVEY_MAIL = 'SEND_SURVEY_MAIL',

  // Pflegelotse routes
  PL_PROCESS_LIST = 'PL_PROCESS_LIST',
  PL_RUN_CREATE = 'PL_RUN_CREATE',
  PL_RUN_LIST = 'PL_RUN_LIST',
  PL_EXPORT = 'PL_EXPORT',
  PL_LIST_IDENTIFIER = 'PL_LIST_IDENTIFIER',
  PL_RUN_UPDATE_STATUS = 'PL_RUN_UPDATE_STATUS',
}

export default Routes
