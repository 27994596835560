import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import props from '../../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { PL_DataSet } from '../../../../types/PL_DataSet'
import usePLExport, { ExportLevel } from '../../../hooks/usePLExport'
import { useSelector } from 'react-redux'
import Container from '../../../UI/Container/Container'
import { PL_Run } from '../../../../types/PL_Run'
import MultiList from '../../../UI/MultiList/MultiList'
import { getPrettyDateTime } from '../../../../utility'

type Document = {
  name: string
  link: string
}

const Run = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { runUUID } = useParams()
  const [run, setRun] = useState<PL_Run | null>(null)
  const [dataSets, setDataSets] = useState<PL_DataSet[] | null>(null)
  const runFeature = crud.use(props.PL_RUN)
  const dataSetFeature = crud.use(props.PL_DATASET)
  const plExport = usePLExport(ExportLevel.DATASET)

  const pullDataSets = useCallback(async () => {
    const dataSets = await dataSetFeature.list({ runUUID })
    if (Array.isArray(dataSets)) {
      setDataSets(dataSets)
    }
  }, [runUUID, dataSetFeature])

  useEffect(() => {
    const pullRun = async () => {
      const run = await runFeature.get({ uuid: runUUID })
      if (!!run) {
        setRun(run)
      }
    }
    if (!dataSets) pullDataSets()
    if (!run) pullRun()
  }, [runUUID, dataSets, runFeature, dataSetFeature, pullDataSets, run])

  const triggerExport = (selectedItemUUIDList: string[], identifier: string) => {
    let fileName = `${`${run.identifier}-${run.process.location.city}-${run.process.location.postcode}`}-vollstaendig.csv`
    if (Array.isArray(selectedItemUUIDList) && selectedItemUUIDList.length > 0) {
      fileName = `${`${run.identifier}-${run.process.location.city}-${run.process.location.postcode}`}-selektiert.csv`
    }
    plExport(fileName, runUUID, selectedItemUUIDList, identifier)
  }

  const filter = (search: string) => (item: PL_DataSet) => {
    return item.title.toLowerCase().includes(search.toLowerCase())
  }

  const getDocuments = (dataSet: PL_DataSet) => {
    const ratingDocumentSearch = dataSet.attachmentLinks.find((link) => !link.includes('vpk_s'))
    const ratingDocument = ratingDocumentSearch ? ratingDocumentSearch : ''
    const maintenanceDocumentSearch = dataSet.attachmentLinks.find((link) => link.includes('vpk_s'))
    const maintenanceDocument = maintenanceDocumentSearch ? maintenanceDocumentSearch : ''
    const documents: Document[] = []
    if (ratingDocument) documents.push({ name: t.pflegelotse.run.detail.document.bewertung, link: ratingDocument })
    if (maintenanceDocument) documents.push({ name: t.pflegelotse.run.detail.document.versorgungsinformationen, link: maintenanceDocument })
    return documents
  }

  const valueParser = [
    (item: PL_DataSet) => (
      <div className="flex flex-col border-b border-blue py-1" key={item.uuid}>
        <div className="font-bold">{item.title}</div>
        {item.phone && <div className="text-sm">{`${t.generic.phonePrefix}${item.phone}`}</div>}
        {item.website && <div className="text-sm">{`${t.generic.websitePrefix}${item.website}`}</div>}
        {item.mail && <div className="text-sm">{`${t.generic.mailPrefix}${item.mail}`}</div>}
        {item.placeVolume && <div className="text-sm">{`${t.pflegelotse.run.detail.placeVolumePrefix}${item.placeVolume}`}</div>}
        {getDocuments(item).length > 0 && (
          <div className="flex flex-row gap-3 flex-wrap">
            <div className="font-bold text-gray text-sm">{t.pflegelotse.run.detail.document.title}</div>
            {getDocuments(item).map((document) => {
              return (
                <a key={document.link} href={document.link} target="_blank" rel="noreferrer" className="cursor-pointer text-blue text-sm">
                  {document.name}
                </a>
              )
            })}
          </div>
        )}
        <div className="text-sm">
          {t.generic.timestampPrefix}
          {getPrettyDateTime(new Date(item.createdOn))}
        </div>
      </div>
    ),
  ]

  if (!run || !Array.isArray(dataSets)) return <></>
  return (
    <Container>
      <MultiList
        title={`${run.identifier} - ${run.process.location.city} (${run.process.location.postcode}) - ${run.process.radius}${t.generic.kilometerSuffix}`}
        list={dataSets}
        filter={filter}
        pull={pullDataSets}
        valueParser={valueParser}
        propTranslationObject={t.pflegelotse.process.list.columns}
        triggerExport={triggerExport}
        identifierContext={{ runUUID }}
      />
    </Container>
  )
}
export default Run
