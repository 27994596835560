import InputMask from 'react-input-mask'
import { BaseInput } from './Input'

type CustomProps = {
  mask: string
}

const MaskInput: React.FC<BaseInput & CustomProps> = ({ value, onChange, mask, className, label }) => {
  const change = (e) => onChange(e.target.value.replaceAll('_', '').replaceAll(' ', ''))

  return (
    <div className={`w-full ${className}`}>
      {label && <label className="text-gray text-sm">{label}</label>}
      <InputMask
        mask={mask}
        alwaysShowMask={true}
        value={value}
        onChange={change}
        className={`border-b border-blue placeholder-gray w-full py-2 px-1 bg-white text-gray rounded-t-sm focus:outline-none focus:shadow-outline`}
      />
    </div>
  )
}

export default MaskInput
