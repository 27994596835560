import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import props from '../../redux/props'
import Routes from '../../redux/routes'
import { setLocal } from '../../redux/action/local'

const useDataFieldManager = () => {
  const dataFields = useSelector((s) => s[props.DATA_FIELD])
  const listDataFields = functional.use(Routes.LOAD_DATA_FIELDS)
  const dispatch = useDispatch()

  useEffect(() => {
    const list = async () => {
      const data = await listDataFields()
      dispatch(setLocal(props.DATA_FIELD, data))
    }
    if (!dataFields) list()
  }, [dataFields, listDataFields, dispatch])

  return dataFields
}

export default useDataFieldManager
