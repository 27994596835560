import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import styled from 'styled-components'
import { DataField, InputType } from '../../../../types/DataField'
import Modal from '../../../UI/Modal/Modal'
import { useState } from 'react'
import { DataFieldEntry } from '../../../../types/DataFieldEntry'
import _ from 'lodash'
import List from './Entries/List'
import Add from './Entries/Add'
import CoreData from './CoreData'
import { Client } from '../../../../types/Client'
import Accordion from '../../../UI/Accordion/Accordion'
import Input from '../../../UI/Input/Input'

const Item = styled.div`
  width: calc(100% / 3 - 10px * 2 / 3);
`

type Props = { clients: Client[]; dataField: DataField; updateCallback: () => void }

const Single: React.FC<Props> = ({ clients, dataField, updateCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const [field, setField] = useState<DataField>(_.cloneDeep(dataField))
  const [entries, setEntries] = useState<DataFieldEntry[]>(_.cloneDeep(dataField.entries))
  const [clientSearch, setClientSearch] = useState<string>('')

  const toggle = () => setOpen(!open)

  const addImported = (addedEntries: DataFieldEntry[]) => {
    const newEntries = Array.isArray(entries) ? [...entries, ...addedEntries] : addedEntries
    setEntries(newEntries)
  }

  const getClientItems = () => {
    const items = clients
      .filter((c) => c.name.toLowerCase().includes(clientSearch.toLowerCase()))
      .map((c) => {
        const matchingEntries = entries.filter((e) => e.clientUUID === c.uuid)
        return {
          key: c.uuid,
          title: `${c.name} (${t.settings.dataField.entry.list.perClientAmount}${matchingEntries.length})`,
          content: (
            <div>
              <List entries={matchingEntries} />
              <Add clientUUID={c.uuid} dataField={field} addImported={addImported} />
            </div>
          ),
        }
      })
    return items
  }

  const genericEntries = entries.filter((e) => !e.clientUUID)

  return (
    <>
      <Item onClick={toggle} className="border border-blue rounded-sm p-3 group hover:bg-blue transition-all cursor-pointer">
        <div className="group-hover:text-white truncate">{field.name}</div>
      </Item>
      <Modal large noForm title={field.name} show={open} onClose={toggle}>
        <CoreData dataField={field} updateCallback={setField} />
        {field.inputType === InputType.DROPDOWN && (
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="flex-1">
              <div className="font-bold text-blue mt-3">
                {t.settings.dataField.entry.list.genericTitle}
                {` (${genericEntries.length})`}
              </div>
              <List entries={genericEntries} />
              <Add dataField={field} addImported={addImported} />
            </div>
            <div className="flex-1">
              <div className="font-bold text-blue mt-3">{t.settings.dataField.entry.list.clientTitle}</div>
              <Input value={clientSearch} onChange={setClientSearch} label placeholder={t.settings.dataField.entry.list.clientFilter} />
              <Accordion className="mt-3" items={getClientItems()} />
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default Single
