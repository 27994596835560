import { Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import EmployeeSurvey from './EmployeeSurvey/EmployeeSurvey'
import ClientSurvey from './ClientSurvey/ClientSurvey'

const Public = () => {
  return (
    <Layout authLevelRequired={false} hideNavigation>
      <Routes>
        <Route path={'mandant/:employeeUUID'} element={<ClientSurvey />} />
        <Route path={'mitarbeiter/:employeeUUID'} element={<EmployeeSurvey />} />
      </Routes>
    </Layout>
  )
}

export default Public
