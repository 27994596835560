import { functional } from '@think-internet/zeus-frontend-package'
import { Member } from '../../../../../types/Member'
import { TrashIcon } from '@heroicons/react/24/outline'
import Modal from '../../../../UI/Modal/Modal'
import Routes from '../../../../../redux/routes'
import props from '../../../../../redux/props'
import useToast, { Type } from '../../../../hooks/useToast'
import { useState } from 'react'

const { useSelector } = require('react-redux')

type Props = { member: Member; callback: () => void }

const Remove: React.FC<Props> = ({ member, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const removeMember = functional.use(Routes.REMOVE_MEMBER)
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    const result = await removeMember({ uuid: member.uuid })
    if (result) {
      callback()
    } else {
      toast(t.settings.access.upsert.remove.error, Type.ERROR)
    }
  }

  return (
    <>
      <TrashIcon className="h-5 stroke-red cursor-pointer" onClick={toggle} />
      <Modal onConfirm={confirm} show={open} onClose={toggle}>
        <div className="text-blue font-bold">{t.settings.access.upsert.remove.title}</div>
        <div className="mt-3">{t.settings.access.upsert.remove.hint}</div>
      </Modal>
    </>
  )
}

export default Remove
