import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { DataField, InputType } from '../../../../types/DataField'
import Input from '../../../UI/Input/Input'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import Section from '../../../UI/Accordion/Section/Section'
import { useState } from 'react'
import styled from 'styled-components'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import _ from 'lodash'
import Form from '../../../UI/Form/Form'
import Button from '../../../UI/Button/Button'

const Item = styled.div`
  width: calc(100% / 2 - 10px / 2);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

type Props = {
  dataField: DataField
  updateCallback: (field: DataField) => void
}

const CoreData: React.FC<Props> = ({ dataField, updateCallback }) => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(dataField.inputType !== InputType.DROPDOWN)
  const toggle = () => setOpen(!open)
  const dataFieldFeature = crud.use(props.DATA_FIELD)
  const [field, setField] = useState<DataField>(_.cloneDeep(dataField))

  const setCheckbox = (key: keyof DataField) => (value: boolean) => setField({ ...field, [key]: value })

  const set = (key: keyof DataField) => (value: string) => setField({ ...field, [key]: value })

  const save = async () => {
    const entry = await dataFieldFeature.update(field)
    if (entry) {
      updateCallback(entry)
      setField(entry)
      toggle()
      toast(t.settings.dataField.edit.success, Type.SUCCESS)
    } else {
      toast(t.settings.dataField.edit.error, Type.ERROR)
    }
  }

  if (!field) return null
  return (
    <div>
      <Section open={open} title={t.settings.dataField.edit.title} clickedCallback={toggle}>
        <Form onSubmit={save} className="flex flex-row flex-wrap gap-[10px] my-3">
          <Item>
            <Input required label placeholder={t.settings.dataField.attributes.name} value={field.name} onChange={set('name')} />
          </Item>
          <Item>
            <Dropdown
              label={t.settings.dataField.attributes.type}
              required
              items={Object.keys(InputType).map((iT) => ({ label: t.settings.dataField.types[iT], value: iT }))}
              value={field.inputType}
              onChange={set('inputType')}
            />
          </Item>
          <Item>
            <Input required label placeholder={t.settings.dataField.attributes.exportName} value={field.exportName} onChange={set('exportName')} />
          </Item>
          <Item>
            <div className="">{t.settings.dataField.attributes.changable.title}</div>
            <div className="flex mt-3">
              <Checkbox
                className="flex-1"
                checked={field.changeableByClient}
                onChange={setCheckbox('changeableByClient')}
                label={t.settings.dataField.attributes.changable.client}
              />
              <Checkbox
                className="flex-1"
                checked={field.changeableByEmployee}
                onChange={setCheckbox('changeableByEmployee')}
                label={t.settings.dataField.attributes.changable.employee}
              />
            </div>
          </Item>
          <div className="w-full flex justify-end">
            <Button type="submit" text={t.settings.dataField.edit.cta} />
          </div>
        </Form>
      </Section>
    </div>
  )
}

export default CoreData
