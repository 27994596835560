import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import logo from '../../../../assets/image/logo.png'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

const Header: React.FC<{ hideNavigation?: boolean }> = ({ hideNavigation = false }) => {
  const navigate = useNavigate()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isLoggedIn = () => !!accountLink

  const goHome = () => {
    let path = '/'
    if (isLoggedIn()) {
      path = accountLink.level === 'ADMIN' ? '/admin' : '/employee'
    }
    navigate(path)
  }

  return (
    <div className="px-3 z-20 top-3 fixed w-full">
      <div className={`shadow-sm transition-all items-center relative flex-row justify-between w-full flex rounded-sm px-3 py-1`}>
        <div className={`absolute top-0 left-0 w-full h-full -z-10 rounded-sm backdrop-blur-sm bg-white bg-opacity-20`}></div>
        <div onClick={goHome} className="flex flex-row gap-3 items-center self-start cursor-pointer">
          <img src={logo} alt="Bohmann-Laing" className="h-9" />
        </div>
        {!hideNavigation && <Navigation />}
      </div>
    </div>
  )
}

export default Header
