import { Client } from './Client'
import { Employee } from './Employee'

export enum Source {
  EMPLOYEE_SURVEY = 'EMPLOYEE_SURVEY',
  CLIENT_SURVEY = 'CLIENT_SURVEY',
}

export enum State {
  ALREADY_SUBMITTED = 'ALREADY_SUBMITTED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
export type LoadReponse = { state: State; data?: Employee; client?: Pick<Client, 'name'> }
