import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { downloadFile } from '../../utility'

export enum ExportLevel {
  PROCESS = 'PROCESS',
  RUN = 'RUN',
  DATASET = 'DATASET',
}

const usePLExport = (level: ExportLevel) => {
  const triggerExport = functional.use(Routes.PL_EXPORT)

  const start = async (filename: string, uuid?: string, selectedUUIDList?: string[], identifier?: string) => {
    const data = await triggerExport({ level, uuid, selectedUUIDList, identifier }, null, null, null, null, 'text/plain')
    if (!!data) {
      downloadFile(filename, data)
    }
  }

  return start
}

export default usePLExport
