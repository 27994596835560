import { useState } from 'react'
import { useSelector } from 'react-redux'
import useToast, { Type } from '../../../hooks/useToast'
import props from '../../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import Modal from '../../../UI/Modal/Modal'
import Button from '../../../UI/Button/Button'
import styled from 'styled-components'
import { Employee } from '../../../../types/Employee'
import DynamicInput from '../../../DynamicInput/DynamicInput'
import CSVImport, { Type as CSVType } from '../../../UI/CSVImport/CSVImport'
import useDataFieldManager from '../../../hooks/useDataFieldManager'
import { DataField } from '../../../../types/DataField'

const InputWrapper = styled.div`
  width: calc(100% / 3 - (10px * 2 / 3));
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

type Props = { successCallback: () => void; children?: React.ReactNode; clientUUID: string; employee?: Employee }

const Upsert: React.FC<Props> = ({ successCallback, children, clientUUID, employee = {} as Employee }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const employeeFeature = crud.use(props.EMPLOYEE)
  const [data, setData] = useState<Employee>(employee)
  const toast = useToast()
  const dataFields: DataField[] = useDataFieldManager()

  const toggle = () => setOpen(!open)

  const upsert = async () => {
    const status = await employeeFeature.upsert({ ...data, clientUUID })
    if (status) {
      toast(t.employee.upsert.success, Type.SUCCESS)
      successCallback()
      toggle()
    } else {
      toast(t.employee.upsert.error, Type.ERROR)
    }
  }

  const set = (key: keyof Employee) => (value: any) => setData({ ...data, [key]: value })

  return (
    <>
      {children ? (
        <div onClick={toggle} className="cursor-pointer">
          {children}
        </div>
      ) : (
        <Button onClick={toggle} text={t.generic.import} />
      )}
      <Modal large onConfirm={upsert} show={open} onClose={toggle}>
        <div className="text-blue font-bold">{!!employee?.uuid ? t.employee.upsert.updateTitle : t.employee.upsert.createTitle}</div>
        {!employee.uuid && <CSVImport type={CSVType.EMPLOYEE} className="mt-3" metaData={{ clientUUID }} successCallback={successCallback} />}
        <div className="flex flex-row flex-wrap gap-[10px] mt-3">
          {dataFields.map((df) => (
            <InputWrapper key={df.uuid}>
              <DynamicInput
                required={false}
                clientUUID={data.clientUUID}
                attribute={df.attribute}
                value={data[df.attribute]}
                onChange={set(df.attribute as keyof Employee)}
              />
            </InputWrapper>
          ))}
        </div>
      </Modal>
    </>
  )
}

export default Upsert
