import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import Input from '../Input/Input'

type Props = {
  className?: string
  color?: string
  onChange?: (value: any) => void
  value?: any
  label?: string
  items?: { label: string; value: any }[]
  required?: boolean
  emptySelection?: string
  showEmptySelection?: boolean
}

const DropdownWithSearch: React.FC<Props> = ({ className = '', color, onChange, value, label, items = [], required = false, emptySelection }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const t = useSelector((s) => s[props.TRANSLATION])

  const toggle = () => setOpen(!open)

  const getCurrentValue = () => {
    const item = items.find((item) => item.value === value)
    return item ? item.label : emptySelection || translation.UI.dropdown.emptySelection
  }

  const set = (item: { label: string; value: any }) => () => {
    onChange(item.value)
    toggle()
  }

  const setFocus = () => {
    if (!open) {
      toggle()
      setSearch('')
    } else {
      toggle()
    }
  }

  const searchFilter = (item: { label: string; value: any }) => {
    return item.label.toLowerCase().includes(search.toLowerCase())
  }

  return (
    <div className={`w-full ${className}`}>
      {label && <label className="text-gray text-sm mb-1">{label}</label>}
      <div className="relative">
        <div
          className={`flex items-center h-[41px] cursor-pointer border-b border-blue w-full py-2 px-1 bg-white text-gray focus:outline-none focus:shadow-outline ${className}`}
          onClick={setFocus}
        >
          <div className="flex items-row items-center w-full relative">
            <input type="text" className="opacity-0 absolute bottom-0 h-[1px]" value={value} required={required} onChange={() => {}} />
            <span className="leading-none w-full text-gray">{getCurrentValue()}</span>
            {open && <ChevronUpIcon className="h-4 w-4 ml-2" />}
            {!open && <ChevronDownIcon className="h-4 w-4 ml-2" />}
          </div>
        </div>
        {open && (
          <div className="absolute flex flex-col w-full left-0 top-full mt-1 bg-white z-10 border rounded-sm border-blue">
            <Input placeholder={t.UI.dropdown.search} onChange={setSearch} value={search} className="text-sm" contentClassName="!py-1" />
            <div className="max-h-40 overflow-x-hidden overflow-y-auto">
              {items.filter(searchFilter).map((item) => (
                <div
                  className="flex items-center border-t border-lightGray first:border-none px-3 py-1 text-sm hover:bg-blue hover:text-white cursor-pointer"
                  key={item.value}
                  onClick={set(item)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DropdownWithSearch
