import { crud } from '@think-internet/zeus-frontend-package'
import { Client } from '../../../types/Client'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { EyeIcon, PencilIcon } from '@heroicons/react/24/solid'
import Input from '../../UI/Input/Input'
import Upsert from './Upsert'
import ArchiveMover, { Mode } from '../../UI/ArchiveMover/ArchiveMover'
import Container from '../../UI/Container/Container'
import CSVExport from '../../UI/CSVExport/CSVExport'
import Routes from '../../../redux/routes'
import Tooltip from '../../UI/Tooltip/Tooltip'
import Toggle from '../../UI/Toggle/Toggle'
import Refresh from '../../UI/Refresh/Refresh'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const clientFeature = crud.use(props.CLIENT)
  const [list, setList] = useState<Client[]>(null)
  const [search, setSearch] = useState<string>('')
  const [showArchived, setShowArchived] = useState<boolean>(false)

  const pull = useCallback(async () => {
    const result: Client[] = await clientFeature.list({})
    if (Array.isArray(result)) {
      setList(result)
      setSearch('')
      setShowArchived(false)
    }
  }, [clientFeature, setSearch, setShowArchived, setList])

  useEffect(() => {
    if (!list) {
      pull()
    }
  }, [list, pull])

  const filter = (item: Client) => {
    if (item.deactivated === true && !showArchived) return false
    return item.name.toLowerCase().includes(search.toLowerCase())
  }

  if (!list) return <></>
  return (
    <Container>
      <div className="flex flex-col gap-1 py-10">
        <div className="flex gap-3 items-center">
          <div className="font-bold text-lg text-blue">{t.client.list.title}</div>
          <Refresh pull={pull} />
          <CSVExport route={Routes.CLIENT_EXPORT} name={'Mandanten'} />
          <Upsert successCallback={pull} />
        </div>
        <Input placeholder={t.client.list.search} onChange={setSearch} value={search} className="mt-5" />
        <Toggle label={t.client.list.showArchived} value={showArchived} onChange={setShowArchived} />
        <div className="mt-5">
          {list.length > 0 && (
            <>
              <div className="flex flex-col lg:flex-row">
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.client.attributes.name}</div>
                </div>
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.client.attributes.nummer}</div>
                </div>
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.client.attributes.bundesland}</div>
                </div>
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.client.list.employeeCount}</div>
                </div>
                <div className="w-36 shrink-0"></div>
              </div>
              {list.filter(filter).map((c) => {
                return (
                  <div className="flex flex-col lg:flex-row" key={c.uuid}>
                    <div className="flex-1 shrink-0">
                      <div>{c.name}</div>
                    </div>
                    <div className="flex-1 shrink-0">{c.nummer}</div>
                    <div className="flex-1 shrink-0">
                      <div>{c.bundesland}</div>
                    </div>
                    <div className="flex-1 shrink-0">
                      <div>{c.employeeCount}</div>
                    </div>
                    <div className="w-36 shrink-0 flex flex-row gap-5 justify-between">
                      <ArchiveMover callback={pull} data={c} mode={Mode.CLIENT} />
                      <Upsert successCallback={pull} client={c}>
                        <Tooltip text={t.client.list.tooltip.editClient}>
                          <PencilIcon className="h-5 fill-blue" />
                        </Tooltip>
                      </Upsert>
                      <a href={`/${accountLink.level}/client/${c.uuid}`}>
                        <Tooltip text={t.client.list.tooltip.goToEmployees}>
                          <EyeIcon className="h-5 fill-blue" />
                        </Tooltip>
                      </a>
                    </div>
                  </div>
                )
              })}
            </>
          )}
          {list.length === 0 && <div className="text-gray text-center text-sm">{t.client.list.noData}</div>}
        </div>
      </div>
    </Container>
  )
}

export default List
