import { crud, functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { PL_Run } from '../../../../types/PL_Run'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Container from '../../../UI/Container/Container'
import { useParams } from 'react-router-dom'
import { PL_Process } from '../../../../types/PL_Process'
import MultiList from '../../../UI/MultiList/MultiList'
import Add from './Add'
import usePLExport, { ExportLevel } from '../../../hooks/usePLExport'

const List = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const plRunList = functional.use(Routes.PL_RUN_LIST)
  const processFeature = crud.use(props.PL_PROCESS)
  const [list, setList] = useState<PL_Run[]>(null)
  const [process, setProcess] = useState<PL_Process>()
  const { processUUID } = useParams()
  const plExport = usePLExport(ExportLevel.RUN)

  const pull = useCallback(async () => {
    const result: PL_Run[] = await plRunList({ processUUID })
    if (Array.isArray(result)) {
      setList(result)
    }
  }, [plRunList, processUUID])

  useEffect(() => {
    const pullProcess = async () => {
      const process: PL_Process = await processFeature.get({ uuid: processUUID })
      if (process) {
        setProcess(process)
      }
    }

    if (!list) {
      pull()
    } else if (!process) {
      pullProcess()
    }
  }, [list, plRunList, pull, processFeature, processUUID, process])

  const filter = (search: string) => (item: PL_Run) => {
    return item.identifier.toLowerCase().includes(search.toLowerCase())
  }

  const headerProps = ['identifier', 'dataSetAmount', 'status.title']
  const valueParser = [(i: PL_Run) => i.identifier, (i: PL_Run) => i.dataSetAmount, (i: PL_Run) => t.pflegelotse.run.list.columns.status[i.status]]
  const linkGenerator = (i: PL_Process) => `/admin/pflegelotse/process/run/${i.uuid}`

  const triggerExport = (selectedItemUUIDList: string[], identifier: string) => {
    const fileName = `${
      identifier
        ? `${identifier}-${process.location.city}-${process.location.postcode}-${process.radius}${t.generic.kilometerSuffix}`
        : `${process.location.city}-${process.location.postcode}-${process.radius}${t.generic.kilometerSuffix}`
    }.csv`
    plExport(fileName, processUUID, selectedItemUUIDList, identifier)
  }

  if (!list || !process) return <></>
  return (
    <Container>
      <MultiList
        title={`${t.pflegelotse.run.list.titlePrefix}${process.location.city} (${process.location.postcode}) - ${process.radius}${t.generic.kilometerSuffix}`}
        list={list}
        filter={filter}
        pull={pull}
        headerProps={headerProps}
        valueParser={valueParser}
        propTranslationObject={t.pflegelotse.run.list.columns}
        linkGenerator={linkGenerator}
        Add={Add}
        triggerExport={triggerExport}
        identifierContext={{ processUUID }}
      />
    </Container>
  )
}

export default List
