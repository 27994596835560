import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import props from '../../../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { useSelector } from 'react-redux'
import Container from '../../../UI/Container/Container'
import Input from '../../../UI/Input/Input'
import { Client } from '../../../../types/Client'
import { Employee } from '../../../../types/Employee'
import Upsert from './Upsert'
import { EyeIcon } from '@heroicons/react/24/solid'
import Routes from '../../../../redux/routes'
import CSVExport from '../../../UI/CSVExport/CSVExport'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import ArchiveMover, { Mode } from '../../../UI/ArchiveMover/ArchiveMover'
import Toggle from '../../../UI/Toggle/Toggle'
import SurveyManager from './SurveyManager'
import Refresh from '../../../UI/Refresh/Refresh'

const EmployeeList = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const [client, setClient] = useState<Client | null>(null)
  const [employees, setEmployees] = useState<Employee[] | null>(null)
  const clientFeature = crud.use(props.CLIENT)
  const employeeFeature = crud.use(props.EMPLOYEE)
  const [search, setSearch] = useState<string>('')
  const [showArchived, setShowArchived] = useState<boolean>(false)

  const pullEmployees = useCallback(async () => {
    const dataSets = await employeeFeature.list({ clientUUID: uuid })
    if (Array.isArray(dataSets)) {
      setEmployees(dataSets)
      setSearch('')
      setShowArchived(false)
    }
  }, [uuid, employeeFeature, setEmployees, setSearch, setShowArchived])

  useEffect(() => {
    const pullClient = async () => {
      const client = await clientFeature.get({ uuid })
      if (!!client) {
        setClient(client)
      }
    }
    if (!employees) pullEmployees()
    if (!client) pullClient()
  }, [uuid, clientFeature, employeeFeature, pullEmployees, employees, client])

  const filter = (item: Employee) => {
    if (item.deactivated === true && !showArchived) return false
    return item.vorname.toLowerCase().includes(search.toLowerCase()) || item.nachname.toLowerCase().includes(search.toLowerCase())
  }

  const updateEmployee = (employee: Employee) => {
    if (!employees) return
    const index = employees.findIndex((e) => e.uuid === employee.uuid)
    if (index !== -1) {
      const newEmployees = [...employees]
      newEmployees[index] = employee
      setEmployees(newEmployees)
    }
  }

  if (!client || !Array.isArray(employees)) return <></>
  return (
    <Container>
      <div className="flex flex-col gap-1 py-10">
        <div className="flex gap-3 items-center">
          <div className="font-bold text-lg text-blue">{`${client.name} - ${client.nummer} - ${client.bundesland}`}</div>
          <Refresh pull={pullEmployees} />
          <CSVExport route={Routes.EMPLOYEE_EXPORT} payload={{ clientUUID: uuid }} name={client.name} />
          <Upsert clientUUID={client.uuid} successCallback={pullEmployees} />
        </div>
        <Input placeholder={t.employee.list.search} onChange={setSearch} value={search} className="mt-5" />
        <Toggle label={t.employee.list.showArchived} value={showArchived} onChange={setShowArchived} />
        <div className="mt-3">
          {employees.filter(filter).length}
          {t.generic.employeeSuffix}
        </div>
        <div className="mt-0">
          {employees.length > 0 && (
            <>
              <div className="flex flex-col lg:flex-row">
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.employee.attributes.vorname}</div>
                </div>
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.employee.attributes.nachname}</div>
                </div>
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.employee.attributes.steuerID}</div>
                </div>
                <div className="flex-1 shrink-0">
                  <div className="font-bold">{t.employee.attributes.email}</div>
                </div>
                <div className="w-20 shrink-0"></div>
              </div>
              {employees.filter(filter).map((e) => {
                return (
                  <div className="flex flex-col lg:flex-row" key={e.uuid}>
                    <div className="flex-1 shrink-0">
                      <div>{e.vorname}</div>
                    </div>
                    <div className="flex-1 shrink-0">{e.nachname}</div>
                    <div className="flex-1 shrink-0">
                      <div>{e.steuerID}</div>
                    </div>
                    <div className="flex-1 shrink-0">
                      <div>{e.email}</div>
                    </div>
                    <div className="w-20 shrink-0 flex flex-row gap-5 justify-between">
                      <ArchiveMover callback={pullEmployees} data={e} mode={Mode.EMPLOYEE} />
                      <Upsert clientUUID={client.uuid} successCallback={pullEmployees} employee={e}>
                        <Tooltip text={t.employee.list.tooltip.showDetail}>
                          <EyeIcon className="h-5 fill-blue" />
                        </Tooltip>
                      </Upsert>
                      <SurveyManager employee={e} callback={updateEmployee} />
                    </div>
                  </div>
                )
              })}
            </>
          )}
          {employees.length === 0 && <div className="text-gray text-center text-sm">{t.employee.list.noData}</div>}
        </div>
      </div>
    </Container>
  )
}
export default EmployeeList
