import { Route, Routes } from 'react-router-dom'
import List from './List'
import EmployeeList from './EmployeeList/EmployeeList'

const Client = () => {
  return (
    <Routes>
      <Route path={'/:uuid'} element={<EmployeeList />} />
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}

export default Client
