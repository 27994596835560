import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Pflegelotse from './Pflegelotse/Pflegelotse'
import Settings from './Settings/Settings'
import Client from '../SharedComponents/Client/Client'

const Admin = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'pflegelotse/*'} element={<Pflegelotse />} />
        <Route path={'client/*'} element={<Client />} />
        <Route path={'settings'} element={<Settings />} />
        <Route path={'*'} element={<Navigate replace to="/admin/client" />} />
      </Routes>
    </Layout>
  )
}

export default Admin
