import { ArchiveBoxArrowDownIcon } from '@heroicons/react/24/solid'
import props from '../../../redux/props'
import { Employee } from '../../../types/Employee'
import { Client } from '../../../types/Client'
import Tooltip from '../Tooltip/Tooltip'
import { useState } from 'react'
import Modal from '../Modal/Modal'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import useToast, { Type } from '../../hooks/useToast'

const { useSelector } = require('react-redux')

export enum Mode {
  CLIENT = 'CLIENT',
  EMPLOYEE = 'EMPLOYEE',
}

type Props = { mode: Mode; data: Client | Employee; callback: () => void }

const ArchiveMover: React.FC<Props> = ({ mode, data, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const toggleArchiveState = functional.use(Routes.TOGGLE_ARCHIVE_STATE)
  const toast = useToast()

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    const result = await toggleArchiveState({ mode, uuid: data.uuid })
    if (result) {
      callback()
      toggle()
    } else {
      toast(t.UI.archiveMover.error, Type.ERROR)
    }
  }

  const getTitle = () => {
    return t.UI.archiveMover.title[data.deactivated ? 'activate' : 'deactivate'](t.UI.archiveMover.type[mode])
  }
  const getHint = () => {
    return t.UI.archiveMover.hint[data.deactivated ? 'activate' : 'deactivate'](t.UI.archiveMover.type[mode])
  }

  return (
    <>
      <Tooltip text={getTitle()}>
        <ArchiveBoxArrowDownIcon className="h-5 fill-blue cursor-pointer" onClick={toggle} />
      </Tooltip>
      <Modal onConfirm={confirm} show={open} onClose={toggle}>
        <div className="text-blue font-bold">{getTitle()}</div>
        <div className="mt-3">{getHint()}</div>
      </Modal>
    </>
  )
}

export default ArchiveMover
